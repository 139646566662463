import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1080p CGI Commands",
  "path": "/1080p_Series_CGI_List/Alarm_Menu/Areas/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1080p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Alarm_Menu/Bereiche/' locationFR='/fr/1080p_Series_CGI_List/Alarm_Menu/Areas/' crumbLabel="1080p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-alarm-menu--alarm-areas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-alarm-menu--alarm-areas",
        "aria-label": "the alarm menu  alarm areas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Alarm Menu :: Alarm Areas`}</h1>
    <hr></hr>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#paramcgicmdgetmdattr"
        }}>{`param.cgi?cmd=getmdattr`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#set-alarm-areas-parameter"
            }}>{`Set Alarm Areas Parameter`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#example"
            }}>{`Example`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdget_instar_admin-index17"
            }}>{`param.cgi?cmd=get_instar_admin&-index=17`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "paramcgicmdgetmdattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetmdattr",
        "aria-label": "paramcgicmdgetmdattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getmdattr`}</h2>
    <h3 {...{
      "id": "set-alarm-areas-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-alarm-areas-parameter",
        "aria-label": "set alarm areas parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set Alarm Areas Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_enable`}</code>{`: Dis/Enable Alarm Detection Area 1 - 4: `}{`[0, 1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_x`}</code>{`: X-Axis Offset of Alarm Area Origin `}{`[0-(1920-w)]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_y`}</code>{`: Y-Axis Offset of Alarm Area Origin `}{`[0-(1080-h)]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_w`}</code>{`: Alarm Area Width `}{`[1-1920]`}{` Pixel`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_h`}</code>{`: Alarm Area Height `}{`[1-1080]`}{` Pixel`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_sensitivity`}</code>{`: Detection Sensitivity `}{`[1 - 100]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`m[1, 2, 3, 4]_threshold`}</code>{`: Detection Threshold (not active)`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getmdattr
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`32`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1856`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1016`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m1_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m2_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m3_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m4_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m5_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m6_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m7_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_x`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_y`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_w`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_h`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`m8_sensitivity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-s`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-w`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1920`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-h`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1080`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-s`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1680`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-w`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-h`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-s`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`840`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-w`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-h`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-s`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1680`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`840`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-w`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-h`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`240`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "paramcgicmdget_instar_admin-index17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdget_instar_admin-index17",
        "aria-label": "paramcgicmdget_instar_admin index17 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=get_instar_admin&-index=17`}</h3>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`get_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`
GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`get_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`
SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`%3B0%3B0%3B0%3B1%3B1`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      